// helpers
import { SmoothNav } from '../../../helpers/SmoothNav';

// components
import { SliderContent, SliderTitle, SliderSubtitle } from '../components';

const Slide3 = () => (
  <SliderContent className="text-center">
    <SliderTitle className="animate__zoomIn animate__slow">Digitaliza tus ideas</SliderTitle>

    <SliderSubtitle className="animate__zoomInDown animate__slow">
      Con la tecnología puedes materializar tus ideas, y nosotros somos los indicados para
      guiarte en ese gran paso a la digitalización
    </SliderSubtitle>

    <span className="animate__animated animate__fadeInUp animate__delay-2s mt-10">
      <a href="#contact" className="slider__content__button" onClick={SmoothNav}>
        Contáctanos
      </a>
    </span>
  </SliderContent>
);

export default Slide3;
