import { useState } from 'react';

import ResponsiveMenu from './components/ResponsiveMenu';
import HeaderNav from './components/HeaderNav';

import './styles.scss';

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const links = [
    { title: 'Inicio', link: '#home' },
    { title: 'Nosotros', link: '#about' },
    { title: 'Servicios', link: '#services' },
    // { title: 'Portafolio', link: '#portafolio' },
    { title: 'Contacto', link: '#contact' },
  ];

  return (
    <>
      <div className="header__space" id="home"></div>

      <HeaderNav links={links} showMenu={showMenu} setShowMenu={setShowMenu} />

      <ResponsiveMenu links={links} showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  );
}
