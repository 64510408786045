import ThemeContext from '.';
import useProvider from './useProvider';

const DarkThemeProvider = ({ children }) => {
  const value = useProvider();

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default DarkThemeProvider;
