import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDarkTheme } from '../../context/DarkTheme';

import './styles.scss';

export default function UpPage() {
  const { scrollY } = useDarkTheme();

  const goTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const showClass = scrollY > 500 ? 'animate__backInUp' : ' animate__backOutDown';

  return (
    <div className={`up__page__container animate__animated ${showClass}`} onClick={goTop}>
      <FontAwesomeIcon icon={faHandPointUp} className="up__page__icon" />
    </div>
  );
}
