// helpers
import { SmoothNav } from '../../../helpers/SmoothNav';

// images
import logo from '../../../assets/img/iwanna.png';

export default function ResponsiveMenu({ links, showMenu, setShowMenu }) {
  const handleStopPropadation = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={`responsive__menu__backdrop ${
        showMenu ? '-translate-x-0' : '-translate-x-full'
      }`}
      onClick={() => setShowMenu(false)}>
      <ul
        className="responsive__menu header__bg__responsive__color"
        onClick={handleStopPropadation}>
        <li>
          <a href="#home" onClick={(e) => SmoothNav(e, setShowMenu(false))}>
            <img src={logo} alt="logo iwannasoftware" width={80} className="drop__shadow" />
          </a>
        </li>
        {links.map((item, k) => (
          <li key={k} className="link uppercase font-semibold">
            <a
              href={item.link}
              label={item.title}
              onClick={(e) => SmoothNav(e, setShowMenu(false))}>
              {' '}
              {item.title}{' '}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
