/* eslint-disable no-useless-escape */

export const email_rgx = {
  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  message: 'Ingrese un correo válido',
};

export const password_rgx = {
  value: /^([\w-\.#$%&])+$/,
  message: 'Solo ingrese letras, números ó ._#-$& (sin espacios)',
};

export const code_rgx = {
  value: /^[\d]{6}$/,
  message: 'Ingrese solo números',
};

export const phone_rgx = {
  value: /^[\d]{10}$/,
  message: 'Ingrese un número telefónico válido',
};
