import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import './style.scss';

// Slides
import Slide1 from './slides/Slide1';
import Slide2 from './slides/Slide2';
import Slide3 from './slides/Slide3';

// Images
import img1 from '../../assets/img/slider/image1.jpg';
import img2 from '../../assets/img/slider/image2.jpg';
import img3 from '../../assets/img/slider/image3.jpg';

export default function Carousel() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const duration = 7000;

  return (
    <AutoplaySlider
      infinite={true}
      play={true}
      interval={duration}
      cancelOnInteraction={false} // should stop playing on user interaction
      bullets={false}
      animation="openAnimation">
      <div data-src={img1}>
        <Slide1 />
      </div>

      <div data-src={img2}>
        <Slide2 />
      </div>

      <div data-src={img3}>
        <Slide3 />
      </div>
    </AutoplaySlider>
  );
}
