import imgMission from '../../../../../assets/img/about/mission.svg';

export default function Vision() {
  return (
    <div className="values__card vision">
      <div className="values__card__image">
        <img src={imgMission} alt="vision-visión" className="drop__shadow" />
      </div>
      <div className="values__card__content">
        <h2>VISION</h2>

        <p>
          Ser más que un proveedor para nuestros clientes brindando la mejor solución, apoyar a
          la sociedad además de nuestros clientes, aportando ideas y caminando a la par de la
          tecnología con un equipo altamente calificado e innovador, crecer a nivel nacional e
          internacional.
        </p>
      </div>
    </div>
  );
}
