import './styles.scss';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

// helpers
import { email_rgx, phone_rgx } from '../../../../helpers/validate/regex';
import { Alert } from '../../../../helpers/Alerts';

export default function Form({ className = '' }) {
  const [loader, setLoader] = useState(false);
  const { errors, handleSubmit, register, reset } = useForm({ mode: 'onSubmit' });

  const onSubmit = (data) => {
    setLoader(true);
    emailjs
      .send('service_7kv4di8', 'template_ew94y6q', data, 'user_rxWy1kO0lhwnv6yFgGKPG')
      .then(
        function (response) {
          // console.log('SUCCESS!', response.status, response.text);
          reset();
          Alert({
            title: 'Mensaje enviado',
            text:
              'Gracias por ponerse en contacto con Iwannasoftware, nos comunicaremos con usted lo antes posible.',
            customClass: {
              confirmButton: 'contact__button__success',
            },
          });
        },
        function (error) {
          console.log('FAILED...', error);
          Alert({
            title: 'Mensaje no enviado',
            text: 'Error interno, por favor vuelva a intentarlo, gracias.',
            icon: 'error',
            customClass: {
              confirmButton: 'contact__button__error',
            },
          });
        }
      )
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input
          type="text"
          name="name"
          placeholder="Nombre completo"
          className={`contact__input ${errors?.name?.message && 'contact__input__error'}`}
          ref={register({
            required: {
              value: true,
              message: 'Requerido',
            },
            minLength: { value: 6, message: 'Ingrese mínimo 6 caracteres' },
            maxLength: { value: 50, message: 'Ingrese máximo 50 caracteres' },
          })}
        />
        <span className="contact__label__error">{errors?.name?.message}</span>
      </div>

      <div>
        <input
          type="text"
          name="email"
          placeholder="Email"
          className={`contact__input ${errors?.email?.message && 'contact__input__error'}`}
          ref={register({
            required: { value: true, message: 'Requerido' },
            pattern: email_rgx,
          })}
        />
        <span className="contact__label__error">{errors?.email?.message}</span>
      </div>

      <div>
        <input
          type="text"
          name="phone"
          placeholder="Teléfono"
          className={`contact__input ${errors?.phone?.message && 'contact__input__error'}`}
          ref={register({ required: false, pattern: phone_rgx })}
        />
        <span className="contact__label__error">{errors?.phone?.message}</span>
      </div>

      <div>
        <textarea
          name="message"
          cols="30"
          rows="4"
          placeholder="Mensaje"
          className={`contact__input ${errors?.message?.message && 'contact__input__error'}`}
          ref={register({
            required: { value: true, message: 'Déjenos un mensaje ;)' },
            minLength: { value: 10, message: 'Ingrese mínimo 10 caracteres' },
            maxLength: { value: 255, message: 'Ingrese máximo 255 caracteres' },
          })}
        />
        <span className="contact__label__error">{errors?.message?.message}</span>
      </div>
      <div className="text-center md:text-left">
        <button className="contact__button" disabled={loader}>
          {loader ? (
            <>
              <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" /> Enviando...
            </>
          ) : (
            'Enviar'
          )}
        </button>
      </div>
    </form>
  );
}
