import { useDarkTheme } from '../../../context/DarkTheme';

import mobile from '../../../assets/img/services/mobile.svg';
import mobileDark from '../../../assets/img/services/mobile_dark.svg';

export default function Mobile() {
  const { darkActive } = useDarkTheme();

  return (
    <div className="services__container">
      <div className="services__image order-1 sm:order-2">
        <span>Desarrollo móvil</span>
        <img src={darkActive ? mobileDark : mobile} alt="vision company" />
      </div>

      <div className="services__text order-2 sm:order-1">
        <div>
          <span>Desarrollo móvil</span>

          <p className="mb-2 text-justify">
            <b>Android: </b>el sistema operativo desarrollado por la empresa Google, es el mas
            utilizado por tablets, phablets y teléfonos en la actualidad.
          </p>
          <p className="text-justify">
            <b>Ios: </b>sistema operativo desarrollado por la empresa Apple, el que utilizan
            todas la tablets y teléfonos de esta empresa (iPads, iPhones).
          </p>
        </div>
      </div>
    </div>
  );
}
