import imgValue from '../../../../../assets/img/about/value.svg';
import imgDot from '../../../../../assets/img/about/right-arrow.svg';

export default function Value() {
  const valores = [
    'Calidad',
    'Compromiso',
    'Equipo',
    'Innovación',
    'Lealtad',
    'Responsabilidad',
    'Pasión',
  ];
  return (
    <div className="values__card">
      <div className="values__card__image">
        <img src={imgValue} alt="values-valores" className="drop__shadow" />
      </div>
      <div className="values__card__content flex flex-col justify-center items-center">
        <h2>VALORES</h2>

        <ul className="">
          {valores.map((item, k) => (
            <li key={k}>
              <img src={imgDot} alt="dot" />
              {`  ${item}`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
