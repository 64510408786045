// helpers
import { SmoothNav } from '../../../helpers/SmoothNav';

// components
import { SliderContent, SliderTitle, SliderSubtitle } from '../components';

const Slide2 = () => (
  <SliderContent>
    <SliderTitle className="animate__fadeIn animate__slow">Software solution</SliderTitle>

    <SliderSubtitle className="animate__zoomInUp animate__slow text-center">
      Desarrollo de software a la medida, solucionamos y automatizamos los procesos de tu
      negocio
    </SliderSubtitle>

    <span className="animate__animated animate__fadeInUp animate__delay-2s mt-10">
      <a href="#services" className="slider__content__button" onClick={SmoothNav}>
        Servicios
      </a>
    </span>
  </SliderContent>
);

export default Slide2;
