import { createContext, useContext } from 'react';

const ThemeContext = createContext({
  darkActive: false,
  toggleTheme: () => {},
  loader: false,
  showLoader: () => {},
  hideLoader: () => {},
  scrollY: 0,
  scrollX: 0,
});

export default ThemeContext;

export const useDarkTheme = () => useContext(ThemeContext);
