// component
import UpPage from './components/UpPage';

// Sections
import Header from './sections/Header';
import Footer from './sections/Footer';

import Carousel from './sections/Carousel';
import About from './sections/About';
import Services from './sections/Servicess';
// import Portafolio from './sections/Portafolio';
import Contact from './sections/Contact';

function App() {
  return (
    <>
      <Header />

      <main>
        <Carousel />

        <About />

        <Services />

        {/* <Portafolio /> */}

        <Contact />
      </main>

      <UpPage />
      <Footer />
    </>
  );
}

export default App;
