import { useEffect, useState } from 'react';
// detecta la configuracion de color del usuario
const userPrefersDark =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
// se obtiene el valor guardado en local storage
const darkAtiveStorage = JSON.parse(localStorage.getItem('darkActive'));
// se valida que existe un valor en localstorage, si no se utiliza las preferencias del usuario
const darkActiveValue = darkAtiveStorage === null ? userPrefersDark : darkAtiveStorage;

export default function useProvider() {
  const [darkActive, setDarkActive] = useState(darkActiveValue);

  const [loader, setLoader] = useState(false);

  const [scrollY, setScrollY] = useState(window.scrollY);
  const [scrollX, setScrollX] = useState(window.scrollX);

  useEffect(() => {
    const HTML = document.querySelector('html');
    if (darkActive) {
      HTML.classList.add('dark');
    } else {
      HTML.classList.remove('dark');
    }
  }, [darkActive]);

  const toggleTheme = () => {
    setDarkActive((value) => {
      const resp = !value;
      localStorage.setItem('darkActive', JSON.stringify(resp));
      return resp;
    });
  };

  const showLoader = () => {
    setLoader(true);
  };
  const hideLoader = () => {
    setLoader(false);
  };

  const scrolling = () => {
    setScrollX(window.scrollX);
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrolling);

    return () => {
      window.removeEventListener('scroll', scrolling);
    };
  }, []);

  return { darkActive, toggleTheme, loader, showLoader, hideLoader, scrollX, scrollY };
}
