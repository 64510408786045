import './styles.scss';

import { faFacebookSquare, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SocialNetwork({ className }) {
  const data = [
    {
      icon: faWhatsappSquare,
      link: 'https://api.whatsapp.com/send?phone=+529621424184',
      label: '(962) 142 41 84',
    },
    {
      icon: faFacebookSquare,
      link: 'https://www.facebook.com/iwannasoftware',
      label: 'facebook.com/iwannasoftware',
    },
    {
      icon: faEnvelopeSquare,
      // link:
      // 'https://mail.google.com/mail/?view=cm&fs=1&to=iwannasoftware@gmail.com&su=Hola desde iwannasoftware.com&',
      link: 'mailto:iwannasoftware@gmail.com',
      label: 'iwannasoftware@gmail.com',
    },
  ];
  return (
    <div className={className}>
      <div className="social__networks">
        {data.map((item, k) => (
          <div key={k} className="social__networks__links">
            <a href={item.link} target="_blank" rel="noreferrer" className="icon">
              <FontAwesomeIcon icon={item.icon} size="3x" />
            </a>

            <a href={item.link} target="_blank" rel="noreferrer" className="label">
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
