import './styles.scss';

// sections
import Web from './sections/Web';
import Mobile from './sections/Mobile';
import Desktop from './sections/Desktop';

export default function Services() {
  return (
    <section className="services" id="services">
      <div className="services__backdrop">
        <div className="size__container">
          <div className="services__def">
            <h2 className="size__title">Servicios</h2>
            <p>
              Resolvemos las necesidades de nuestros clientes automatizando sus servicios y
              procesos, mejorando el rendimiento de su empresa a través del desarrollo de
              sistemas a la medida, con las tecnologías mas actuales y para todo tipo de
              dispositivos y plataformas
            </p>
          </div>

          <Web />

          <Mobile />

          <Desktop />
        </div>
      </div>
    </section>
  );
}
