import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const MySwal = withReactContent(Swal);

export const Alert = ({
  title = null,
  text = null,
  html = null,
  icon = 'success',
  confirmButtonText = 'Aceptar',
  customClass = {},
}) => {
  MySwal.fire({
    title,
    text,
    html,
    icon,
    confirmButtonText,
    customClass: { ...customClass },
  });
};

export const Toast = ({
  title,
  icon = 'success',
  position = 'top-end',
  timer = 3000,
  timerProgressBar = true,
  showConfirmButton = false,
}) => {
  const ToastConfig = Swal.mixin({
    toast: true,
    position,
    showConfirmButton,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  ToastConfig.fire({
    icon: title ? icon : 'error',
    html: title ? title : 'without title',
  });
};
