export const SmoothNav = (event, callback = () => {}) => {
  event.preventDefault();
  callback();

  const href = event.target.hash;
  let offset = href !== undefined ? document.querySelector(href).offsetTop : 0;
  offset = offset - 50 < 0 ? 0 : offset - 50;

  window.scroll({ top: offset, behavior: 'smooth' });
};
