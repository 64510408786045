// context
import { useDarkTheme } from '../../../context/DarkTheme';

// helpers
import { SmoothNav } from '../../../helpers/SmoothNav';

// iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

// images
import logo from '../../../assets/img/logo.png';
import { useState } from 'react';
import { useEffect } from 'react';

export default function HeaderNav({ links, setShowMenu, showMenu }) {
  const { toggleTheme, darkActive, scrollY } = useDarkTheme();

  const [navTop, setNavTop] = useState(true);

  useEffect(() => {
    const about = document.querySelector('#about').offsetTop;
    setNavTop(scrollY < about - 70);
  }, [scrollY]);

  return (
    <header className={navTop ? 'header header__bg__color__top' : 'header header__bg__color'}>
      <div className="header__logo">
        <div className="header__logo__image">
          <a
            href="#home"
            onClick={SmoothNav}
            className={navTop ? 'animate__animated animate__zoomIn ' : 'hidden'}>
            <img src={logo} alt="logo iwannasoftware" />
          </a>
        </div>

        <div className="header__logo__label">
          <a
            href="#home"
            onClick={SmoothNav}
            className={navTop ? 'hidden' : 'animate__animated animate__fadeInUp '}>
            Iwannasoftware
          </a>
        </div>
      </div>

      <nav className="header__nav">
        <ul className="header__ul">
          {links.map((item, k) => (
            <li className="header__li link" key={k}>
              <a href={item.link} onClick={SmoothNav}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>

        <FontAwesomeIcon
          icon={darkActive ? faSun : faMoon}
          onClick={toggleTheme}
          className="header__toggle__theme link"
        />

        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          className={`header__responsive__menu ${showMenu ? 'active' : ''}`}
          onClick={() => setShowMenu(true)}
        />
      </nav>
    </header>
  );
}
