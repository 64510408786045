// helpers
import { SmoothNav } from '../../../helpers/SmoothNav';

// components
import { SliderContent, SliderTitle, SliderSubtitle } from '../components';

const Slide1 = () => (
  <SliderContent className="text-center">
    <SliderTitle className="animate__backInRight animate__slow">
      Bienvenido a IwannaSoftware
    </SliderTitle>

    <SliderSubtitle className="animate__backInLeft animate__slow text-center">
      Desde la fundación de este gran equipo, se nos ha caracterizado por nuestros valores,
      profesionalismo y la calidad de nuestros proyectos
    </SliderSubtitle>

    <span className="animate__animated animate__fadeInUp animate__delay-2s mt-10">
      <a href="#about" className="slider__content__button" onClick={SmoothNav}>
        Conócenos
      </a>
    </span>
  </SliderContent>
);

export default Slide1;
