import './styles.scss';

export default function index() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer" id="footer">
      <div className="footer__copyright">{year}© iwannasoftware®</div>
    </footer>
  );
}
