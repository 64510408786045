import fullLogo from '../../../../assets/img/iwanna.png';

import './styles.scss';

export default function Somos() {
  return (
    <div className="size__container somos__container">
      <div className="somos__logo">
        <img src={fullLogo} alt="iwannasoftware full logo" className="drop__shadow__10" />
      </div>

      <div className="somos__title">
        <h2 className="size__title">¿Que es Iwannasoftware?</h2>
      </div>

      <div className="somos__description">
        <p className="text-justify">
          Somos una empresa integral y con experiencia, fundada a mediados del 2016, por
          ingenieros de software egresados de la Universidad Politécnica de Tapachula (UPTap),
          con el objetivo de brindar soluciones a problemas cotidianos apoyados con la
          tecnología mas actual.
        </p>
      </div>
    </div>
  );
}
