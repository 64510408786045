import Vision from './components/Vision';
import Mission from './components/Mission';
import Values from './components/Values';

import './styles.scss';

export default function Services() {
  return (
    <div className="values">
      <div className="size__container">
        <div className="values__card__container">
          <Vision />

          <Mission />

          <Values />
        </div>
      </div>
    </div>
  );
}
