import imgVision from '../../../../../assets/img/about/vision.svg';

export default function Ios() {
  // a4da2a
  // 909090
  return (
    <div className="values__card">
      <div className="values__card__image">
        <img src={imgVision} alt="misión-mission" className="drop__shadow" />
      </div>
      <div className="values__card__content">
        <h2>MISIÓN</h2>

        <p>
          Elaborar productos y ofrecer servicios Tecnológicos innovadores de calidad, con el
          objetivo de incrementar la competitividad y productividad de nuestros clientes
          generándoles valor a sus ideas y necesidades.
        </p>
      </div>
    </div>
  );
}
