import { useDarkTheme } from '../../../context/DarkTheme';

import web from '../../../assets/img/services/web.svg';
import webDark from '../../../assets/img/services/web_dark.svg';

export default function Web() {
  const { darkActive } = useDarkTheme();

  return (
    <div className="services__container">
      <div className="services__image sm:rounded-tl-3xl">
        <span>Desarrollo web</span>
        <img src={darkActive ? webDark : web} alt="mission company" />
      </div>

      <div className="services__text sm:rounded-tr-3xl">
        <div>
          <span>Desarrollo web</span>

          <p className="text-justify">
            <b>Tu empresa en linea,</b> diseñamos, desarrollamos y ponemos en internet tu
            pagina web con la mejor y mas actual tecnología, para darte a conocer al mundo
            digital y expandir el alcance de tu empresa, llegando a mas clientes y/o usuarios.
          </p>
        </div>
      </div>
    </div>
  );
}
