import Somos from './components/Somos';
import Values from './components/Values';
export default function Iwanna() {
  return (
    <section id="about">
      <Somos />

      <Values />
    </section>
  );
}
