import './styles.scss';

import Form from './components/Form';
import SocialNetwork from './components/SocialNetworks';

export default function Contact() {
  return (
    <section id="contact" className="contact ">
      <div className="contact__container size__container">
        <h2 className="contact__title size__title">Contáctanos</h2>

        <SocialNetwork className="contact__social__networks" />

        <Form className="contact__form__container" />
      </div>
    </section>
  );
}
