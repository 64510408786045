import { useDarkTheme } from '../../../context/DarkTheme';

import desktop from '../../../assets/img/services/desktop.svg';
import desktopDark from '../../../assets/img/services/desktop_dark.svg';

export default function Desktop() {
  const { darkActive } = useDarkTheme();

  return (
    <div className="services__container">
      <div className="services__image sm:rounded-bl-3xl">
        <span>Aplicación de Escritorio</span>
        <img src={darkActive ? desktopDark : desktop} alt="values company" />
      </div>

      <div className="services__text sm:rounded-br-3xl">
        <div>
          <span>Aplicación de Escritorio</span>

          <p className="text-justify">
            Diseñamos y desarrollamos el software a la medida para tu empresa, en un entorno
            PC's de escritorio, para su comodidad y la de su personal, haciendo mas eficiente
            su labor.
          </p>
        </div>
      </div>
    </div>
  );
}
